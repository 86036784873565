<template>
    <div>
        <search-header :currentActive="6"></search-header>
        <div style="position: relative;">
            <van-image :src="require('@/assets/img/join.png')"></van-image>
            <div class="join">
                <div style="font-size: 60px;font-weight: 700;">招商加盟</div>
                <div class="f-24">土地流转投资项目，国资战略合作商</div>
                <div @click="scrollToBottom('#apply')" class="button">
                    点击申请合作
                </div>
            </div>
        </div>
      
        <div class="container">
            <div class="row">
                <div class="sectionTitle">
                    <div class="circle"></div>
                    <div class="mainTitle">
                        八大盈利模式
                    </div>
                    <div class="desc">
                        Cooperation advantage
                    </div>
                </div>
                <div class="col-12 border p-0 py-4 f-24 rounded clearfix">
                    <div class="w-50 float-left">
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>土地流转中介费</div>
                            <div class="text-theme">3%-5%</div>
                        </div>
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>项目申报服务费</div>
                            <div class="text-theme">1万-100万元</div>
                        </div>
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>土地测绘</div>
                            <div class="text-theme">2%-3%</div>
                        </div>
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>土地相关手续、权证办理</div>
                            <div class="text-theme">5000-20000元</div>
                        </div>
                    </div>
                    <div class="w-50 float-left">
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>平台广告费</div>
                            <div class="text-theme">500-5000元/次</div>
                        </div>
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>客户带看费</div>
                            <div class="text-theme">200-2000元/次</div>
                        </div>
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>农业社会化服务费</div>
                            <div class="text-theme">3%-5%</div>
                        </div>
                        <div class="d-flex justify-content-between py-2 px-5 align-items-center">
                            <div>土地项目委托费</div>
                            <div class="text-theme">1%-2%</div>
                        </div>
                    </div>
                </div>

                <div class="sectionTitle">
                    <div class="circle"></div>
                    <div class="mainTitle">
                        润宇合作优势
                    </div>
                    <div class="desc">
                        Cooperation advantage
                    </div>
                </div>

                <div class="col p-0">
                    <b-card
                    title="政府扶持"
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-top
                    class="mb-2"
                  >
                    <b-card-text>
                        农业部、农投、江西省农经局、抚州市政府等单位。
                    </b-card-text>
                  </b-card>
                </div>

                <div class="col">
                    <b-card
                    title="金融便捷"
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-top
                    class="mb-2"
                  >
                    <b-card-text>
                        与多个银行达成合作，提供快捷低息贷款及理财产品。
                    </b-card-text>
                  </b-card>
                </div>

                <div class="col">
                    <b-card
                    title="产业联盟"
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-top
                    class="mb-2"
                  >
                    <b-card-text>
                        在江西省建立了多个乡镇办事处，江西省覆盖率高达98%
                    </b-card-text>
                  </b-card>
                </div>

                <div class="col">
                    <b-card
                    title="海量资源"
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-top
                    class="mb-2"
                  >
                    <b-card-text>
                        土地挂牌面积已达5.96亿亩，已成交13000亩。
                    </b-card-text>
                  </b-card>
                </div>

                <div class="col">
                    <b-card
                    title="配套工具"
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-top
                    class="mb-2"
                  >
                    <b-card-text>
                        土地测绘、农机具、土地评估、土地数据中心、土地流转ERP
                    </b-card-text>
                  </b-card>
                </div>


                <section>
                    <div class="sectionTitle">
                        <div class="circle"></div>
                        <div class="mainTitle">
                            成为我们的服务商，您将获得
                        </div>
                        <div class="desc">
                            Become our agent and you will get
                        </div>
                    </div>
                    <div class="box">
                        <div class="box_in justify-content-center">
                            <ul class="width-300 ml-4">
                                <li>
                                    <span class="num">
                                        1
                                    </span>
                                    <span>
                                        推广支持
                                    </span>
                                </li>
                                <li>
                                    <span class="num">
                                        2
                                    </span>
                                    <span>
                                        客源支持
                                    </span>
                                </li>
                                <li>
                                    <span class="num">
                                        3
                                    </span>
                                    <span>
                                        品牌支持
                                    </span>
                                </li>
                                <li>
                                    <span class="num">
                                        4
                                    </span>
                                    <span>
                                        开业支持
                                    </span>
                                </li>
                            </ul>
                            <ul class="width-300 ml-5">
                                <li>
                                    <span class="num">
                                        5
                                    </span>
                                    <span>
                                        培训体系
                                    </span>
                                </li>
                                <li>
                                    <span class="num">
                                        6
                                    </span>
                                    <span>
                                        市场支持
                                    </span>
                                </li>
                                <li>
                                    <span class="num">
                                        7
                                    </span>
                                    <span>
                                        技术支持
                                    </span>
                                </li>
                                <li>
                                    <span class="num">
                                        8
                                    </span>
                                    <span>
                                        运营支持
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="imgContainer">
                            <van-image :src="require('@/assets/img/hzgy.png')" width="500" height="500" radius="500"></van-image>
                        </div>
                    </div>
                </section>


                <div class="col-6 P-0">
                    <div class="sectionTitle flexTitle">
                        <div class="circle">
                        </div>
                        <div class="mainTitle">
                            合作条件
                        </div>
                        <div class="desc">
                            Terms for cooperation
                        </div>
                    </div>
                    <div class="box">
                        <ul>
                            <li class="mt-0 d-flex align-items-center">
                                <span class="num">
                                    1
                                </span>
                                <span>
                                    认同农村综合产权服务平台核心价值
                                </span>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="num">
                                    2
                                </span>
                                <span>
                                    注册一家公司及相关合法资质
                                </span>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="num">
                                    3
                                </span>
                                <span>
                                    拥有办公场地及3-5运营团队
                                </span>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="num">
                                    4
                                </span>
                                <span>
                                    认可公司企业文化，相信土地流转市场潜力
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-6 P-0">
                    <div class="sectionTitle flexTitle">
                        <div class="circle">
                        </div>
                        <div class="mainTitle">
                            联系我们
                        </div>
                        <div class="desc">
                            Contact us
                        </div>
                    </div>
                    <div class="box">
                    <ul>
                        <li class="mt-0 d-flex align-items-center">
                            <van-image :src="require('@/assets/icon/lxwm-p.png')"></van-image>
                            <span class="ml-2">
                                 招商电话：0794-8269907
                            </span>
                        </li>
                        <li class="d-flex align-items-center">
                            <van-image :src="require('@/assets/icon/lxwm-e.png')"></van-image>
                            <span class="ml-2">
                                电子邮箱：jxry0706@163.com
                            </span>
                        </li>
                    </ul>
                    </div>
                </div>
                

                <div id="apply" class="col-12">
                    <div class="text-center f-36">ONLINE AFFILIATE</div>
                    <div class="text-center text-success f-24"> ─ 加入我们 ─</div>
                </div>
                <div class="col-4">
                    <label>姓名：</label>  
                    <input placeholder="请填写您的姓名" class="form-control mr-sm-2" maxlength="20" type="text"/>
                </div>
                <div class="col-4">
                    <label>电话：</label>  
                    <input placeholder="请填写您的联系方式" class="form-control mr-sm-2" maxlength="20" type="text"/>
                </div>
                <div class="col-4">
                    <label>城市：</label>  
                    <div class="areabox box">
                        <input @click.stop="isAreaShow = !isAreaShow" :value="areaValue" class="form-control" type="text" placeholder="请选择您的服务城市" readonly="readonly">
                        <em @click.stop="isAreaShow = !isAreaShow">
                            <b-icon icon="chevron-down"></b-icon>
                        </em>
                        <city v-model="isAreaShow" @onChoose="onChooseArea" :isDistrictShow="false"></city>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center" style="margin-bottom: 60px;margin-top:40px">
                   <b-button variant="success" class="width-240">立即申请</b-button>
                </div>
            </div>
        </div>
        <map-footer></map-footer>
    </div>
</template>

<script>
import city from "@/components/city/city.vue";
export default {
    components: { city },
    data() {
        return {
            isAreaShow: false,
            areaValue: '',
        }
    },
    methods: {
        scrollToBottom(key) {
            var m = this.$el.querySelector(key)
            document.body.scrollTop = m.offsetTop; // chrome
            document.documentElement.scrollTop = m.offsetTop; // firefox
        },
        onChooseArea() {

        },
        onChoose () {

        }
    }
}
</script>

<style scoped>
    .sectionTitle {
        position: relative;
        text-align: left;
        width: 1140px;
        margin: 0 auto;
        height: 85px;
        margin-top: 60px;
        margin-bottom: 40px;
    }
    .sectionTitle .mainTitle {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 32px;
        font-weight: 600;
        color: #222;
    }

    .sectionTitle .desc {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 28px;
        color: #a4a4a4;
    }

    .sectionTitle .circle{
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;
        background: #00CC00;
        border-radius: 50%;
    }

    .box {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }
    .box .box_in{
        width: 400px;
        display: flex;
        justify-content: space-between;
        text-align: left;
    }

    .box li {
        margin-top: 40px;
        font-size: 24px;
        color: #343434;
    }

    .box .num {
        display: inline-block;
        background: #00CC00;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        line-height: 30px;
        margin-right: 12px;
        font-size: 22px;
    }

    .imgContainer{
        margin-top: -60px;
        margin-left: 180px;
    }

    .join {
        position: absolute;
        top: 30%;
        display: flex;
        flex-direction: column; 
        justify-content: center; 
        align-items: center;
        width: 100%; 
        color: #ffffff;
    }
    .join .button {
        background: #00CC00;
        color: #fff;
        width: 384px;
        margin-top: 20px;
        height: 60px;
        line-height: 60px;
        border-radius: 30px;
        font-size: 24px;
        text-align: center;
        cursor: pointer;
    }
    #apply {
        margin-top: 60px;
        margin-bottom: 30px;

    }
</style>
